<template>
  <div>
    <!-- <button @click="Separate" style="position: absolute; top: 20px; left: 20px">
      分离</button
    ><button @click="Merge" style="position: absolute; top: 20px; left: 70px">
      回归
    </button> -->
    
    <!-- <table style="position:absolute;top:80px;left:20px;"><tr>
    <td>长:</td><td><input type="text" id="lengthP" style="width:50px"></td>
    <td>宽:</td><input type="text" id="widthP" style="width:50px"><td></td>
    <td><button @click="addWL">添加</button></td></tr></table> -->
    <div id="recognizeresult" style="margin:10px;display:none;"></div>
    <table><tr><td valign="top"><table id="recognizeresulttable" border="2"></table></td>
    <td>
      <button
      @click="ChangeCamera"
      style=""
    >
      相机切换
    </button>
    <div id="threejsdiv"></div>
    </td>
    </tr></table>
  </div>
</template>

<script type="module">
// import * as THREE from "../../utils/three.module.js";
// import { OrbitControls } from '../../utils/OrbitControls.js'
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
let camera, controls, scene, renderer,fontP;
let newWL = [];

export default {
  name: "HelloWorld",
  data() {
    return {
      cameratype: "p",
      planeXLength: 3000,
      planeYLength: 25,
      planeZLength: 2000,
      flGroup: [],
      flCount: null,
      flGroupCurrentHeight: 0,
      //font: null,
      //newWL: [],
    };
  },
  props: {
    msg: String,
  },
  mounted: function () {
    const loader = new THREE.FontLoader();
    loader.load("/utils/DengXian Light_Regular.json", (font) => {
      fontP = font;
      this.initThreejs();
      this.animate();
    });

    const resultStr = this.$route.query.result;
    
    let tableStr = resultStr
      .replace("<html><body>", "")
      .replace("</body></html>", "")
      .replace("<table>", '<table border="2">')
      .replaceAll(
        "</tr>",
        '<td>长:</td><td><input name="lengthP" type="text" style="width:50px;"></td><td>宽:</td><td><input type="text" name="widthP" style="width:50px;"></td><td><button onclick="addWl(this)">添加</button></td></tr>'
      );

    // let tableStr = resultStr
    //   .replace("<html><body>", "")
    //   .replace("</body></html>", "")
    //   .replace("<table>", '<table border="2">');
    
    console.log(tableStr.search("</tr>"))
    document.getElementById("recognizeresult").innerHTML = tableStr;

    var tb = document.getElementById("recognizeresult").childNodes[0];    // table 的 id
    var rows = tb.rows;                           // 获取表格所有行
    var trHTML = "";
    var trHTMLArr = [];
    for(var i = 0; i<rows.length; i++ ){
      trHTMLArr.push("<tr>" + rows[i].innerHTML + "</tr>");
    }

    for(var i = trHTMLArr.length - 1;i >= 0;i--){
      trHTML += trHTMLArr[i];
    }

    document.getElementById('recognizeresulttable').innerHTML = trHTML;

    var blob = new Blob([document.getElementById('recognizeresulttable').parentNode.innerHTML.replaceAll('<td>长:</td><td><input name="lengthP" type="text" style="width:50px;"></td><td>宽:</td><td><input type="text" name="widthP" style="width:50px;"></td><td><button onclick="addWl(this)">添加</button></td></tr>',"</tr>")], { type: "application/vnd.ms-excel" });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "识别结果.xls"; // 这里填保存成的文件名
    a.innerHTML = "识别结果.xls";
    document.getElementById("recognizeresulttable").parentNode.appendChild(a);

    window.addWl = function (e) {
      let lengthP = parseFloat(e.parentNode.previousElementSibling.previousElementSibling.previousElementSibling.childNodes[0].value);
      let widthP = parseFloat(e.parentNode.previousElementSibling.childNodes[0].value);
      
      if (isNaN(lengthP) | isNaN(widthP)) {
        alert("请输入长宽");
        return;
      }
      var positionX = 0;
      for (let i = 0; i < newWL.length; i++) {
        positionX += parseFloat(newWL[i]);
      }
      newWL.push(lengthP);
      
      let fontGeo = new THREE.TextBufferGeometry("新添加", {
        size: 30, //字号大小，一般为大写字母的高度
        height: 1, //文字的厚度
        weight: "normal", //值为'normal'或'bold'，表示是否加粗
        font: fontP, //字体，默认是'helvetiker'，需对应引用的字体文件
        style: "normal", //值为'normal'或'italics'，表示是否斜体
        bevelThickness: 1, //倒角厚度
        bevelSize: 1, //倒角宽度
        curveSegments: 30, //弧线分段数，使得文字的曲线更加光滑
        bevelEnabled: true, //布尔值，是否使用倒角，意为在边缘处斜切
      });
      let fontMat = new THREE.MeshPhongMaterial({
        color: 0xcccccc,
        shininess: 60,
        specular: 0xcccccc,
      });
      let fontMesh = new THREE.Mesh(fontGeo, fontMat);
      fontGeo.rotateX(-Math.PI / 2);
      fontGeo.translate(
        -1500 + lengthP / 2 - 50 + positionX,
        25,
        1000 - widthP / 2
      );

      scene.add(fontMesh);

      var geometry = new THREE.BoxBufferGeometry(lengthP, 25, widthP);
      geometry.translate(
        -1500 + lengthP / 2 + positionX,
        12.5,
        1000 - widthP / 2
      );

      var material = new THREE.MeshBasicMaterial({ color: 0xcccccc });
      var cylinder = new THREE.Mesh(geometry, material);
      scene.add(cylinder);

      var edges = new THREE.EdgesGeometry(geometry);
      var lineMaterial = new THREE.LineBasicMaterial({
        color: "#000",
        linewidth: 1,
      });
      var cubeLine = new THREE.LineSegments(edges, lineMaterial);
      scene.add(cubeLine);
    };
  },
  methods: {
    initThreejs() {
      scene = new THREE.Scene();
      scene.background = new THREE.Color("#fff");
      //scene.fog = new THREE.FogExp2( 0xcccccc, 0.002 );

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth / 1.3, window.innerHeight / 1.3);
      document.getElementById('threejsdiv').appendChild(renderer.domElement);

      camera = new THREE.PerspectiveCamera(
        60,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );
      camera.position.set(0, 1500, 2500);

      // controls

      controls = new OrbitControls(camera, renderer.domElement);

      // 画板子
      this.DrawPlane(this.planeXLength, this.planeYLength, this.planeZLength); //板子宽，高，长

      // // 画法兰I
      // this.DrawFLMP(
      //   -this.planeXLength / 2 + 270 / 2,
      //   25 / 2,
      //   this.planeZLength / 2 - 270 / 2,
      //   270,
      //   25,
      //   270
      // );
      // this.DrawFL(
      //   -this.planeXLength / 2 + 260 / 2,
      //   20 / 2,
      //   this.planeZLength / 2 - 260 / 2,
      //   260 / 2,
      //   20
      // );

      // this.DrawFLMP(
      //   -this.planeXLength / 2 + 270 / 2,
      //   25 / 2,
      //   this.planeZLength / 2 - 270 / 2 - 270,
      //   270,
      //   25,
      //   270
      // );
      // this.DrawFL(
      //   -this.planeXLength / 2 + 260 / 2,
      //   20 / 2,
      //   this.planeZLength / 2 - 270 / 2 - 270,
      //   260 / 2,
      //   20
      // );

      // this.DrawFLMP(
      //   -this.planeXLength / 2 + 270 / 2,
      //   25 / 2,
      //   this.planeZLength / 2 - 270 / 2 - 270 * 2,
      //   270,
      //   25,
      //   270
      // );
      // this.DrawFL(
      //   -this.planeXLength / 2 + 260 / 2,
      //   20 / 2,
      //   this.planeZLength / 2 - 270 / 2 - 270 * 2,
      //   260 / 2,
      //   20
      // );

      // // 画垫板
      // this.DrawDBMP(
      //   -this.planeXLength / 2 + 270 + 490 / 2,
      //   25 / 2,
      //   this.planeZLength / 2 - 360 / 2,
      //   490,
      //   25,
      //   360
      // );
      // this.DrawDB(
      //   -this.planeXLength / 2 + 270 + 490 / 2,
      //   25 / 2,
      //   this.planeZLength / 2 - 350 / 2,
      //   490,
      //   25,
      //   350
      // );
      // this.DrawDBMP(
      //   -this.planeXLength / 2 + 270 + 490 / 2,
      //   25 / 2,
      //   this.planeZLength / 2 - 360 / 2 - 360,
      //   490,
      //   25,
      //   360
      // );
      // this.DrawDB(
      //   -this.planeXLength / 2 + 270 + 490 / 2,
      //   25 / 2,
      //   this.planeZLength / 2 - 350 / 2 - 360,
      //   490,
      //   25,
      //   350
      // );

      const ambientLight = new THREE.AmbientLight(0x222222);
      scene.add(ambientLight);

      // var gridHelper = new THREE.GridHelper( this.planeXLength + 1000, 30, 0x2C2C2C, 0x888888 );
      // scene.add(gridHelper);
      // var axesHelper=new THREE.AxesHelper(100);
      // scene.add(axesHelper);
      this.render();
      window.addEventListener("resize", this.onWindowResize);
    },
    addWL() {
      let lengthP = parseFloat(document.getElementById("lengthP").value);
      let widthP = parseFloat(document.getElementById("widthP").value);

      if (isNaN(lengthP) | isNaN(widthP)) {
        alert("请输入长宽");
        return;
      }
      var positionX = 0;
      for (let i = 0; i < this.newWL.length; i++) {
        positionX += this.newWL[i];
      }
      this.newWL.push(lengthP);

      let fontGeo = new THREE.TextBufferGeometry("新添加", {
        size: 30, //字号大小，一般为大写字母的高度
        height: 1, //文字的厚度
        weight: "normal", //值为'normal'或'bold'，表示是否加粗
        font: this.font, //字体，默认是'helvetiker'，需对应引用的字体文件
        style: "normal", //值为'normal'或'italics'，表示是否斜体
        bevelThickness: 1, //倒角厚度
        bevelSize: 1, //倒角宽度
        curveSegments: 30, //弧线分段数，使得文字的曲线更加光滑
        bevelEnabled: true, //布尔值，是否使用倒角，意为在边缘处斜切
      });
      let fontMat = new THREE.MeshPhongMaterial({
        color: 0xcccccc,
        shininess: 60,
        specular: 0xcccccc,
      });
      let fontMesh = new THREE.Mesh(fontGeo, fontMat);
      fontGeo.rotateX(-Math.PI / 2);
      fontGeo.translate(
        -740 + lengthP / 2 - 50 + positionX,
        25,
        1000 - widthP / 2
      );

      scene.add(fontMesh);

      const geometry = new THREE.BoxBufferGeometry(lengthP, 25, widthP);
      geometry.translate(
        -740 + lengthP / 2 + positionX,
        12.5,
        1000 - widthP / 2
      );

      const material = new THREE.MeshBasicMaterial({ color: 0xcccccc });
      const cylinder = new THREE.Mesh(geometry, material);
      scene.add(cylinder);

      const edges = new THREE.EdgesGeometry(geometry);
      const lineMaterial = new THREE.LineBasicMaterial({
        color: "#000",
        linewidth: 1,
      });
      const cubeLine = new THREE.LineSegments(edges, lineMaterial);
      scene.add(cubeLine);
    },
    onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    },
    animate() {
      requestAnimationFrame(this.animate);
      controls.update(); // only required if controls.enableDamping = true, or if controls.autoRotate = true
      this.render();
    },
    render() {
      renderer.render(scene, camera);
    },
    DrawPlane(xLength, yLength, zLength) {
      const geometry = new THREE.BoxBufferGeometry(xLength, yLength, zLength);
      geometry.translate(0, yLength / 2, 0);

      const material = new THREE.MeshBasicMaterial({
        color: 0xcccccc,
        transparent: true,
        opacity: 0.5,
        side: THREE.BackSide,
      });
      const cube = new THREE.Mesh(geometry, material);
      scene.add(cube);

      const edges = new THREE.EdgesGeometry(geometry);
      const lineMaterial = new THREE.LineBasicMaterial({
        color: "#000",
        linewidth: 1,
      });
      const cubeLine = new THREE.LineSegments(edges, lineMaterial);
      scene.add(cubeLine);
    },
    DrawFL(x, y, z, r, thick) {
      var flGroup = new THREE.Group();
      let fontGeo = new THREE.TextBufferGeometry("法兰Ⅰ", {
        size: 30, //字号大小，一般为大写字母的高度
        height: 10, //文字的厚度
        weight: "normal", //值为'normal'或'bold'，表示是否加粗
        font: this.font, //字体，默认是'helvetiker'，需对应引用的字体文件
        style: "normal", //值为'normal'或'italics'，表示是否斜体
        bevelThickness: 1, //倒角厚度
        bevelSize: 1, //倒角宽度
        curveSegments: 30, //弧线分段数，使得文字的曲线更加光滑
        bevelEnabled: true, //布尔值，是否使用倒角，意为在边缘处斜切
      });
      let fontMat = new THREE.MeshPhongMaterial({
        color: 0xcccccc,
        shininess: 60,
        specular: 0xcccccc,
      });
      let fontMesh = new THREE.Mesh(fontGeo, fontMat);
      fontGeo.rotateX(-Math.PI / 2);
      fontGeo.translate(x - 50, y, z);

      flGroup.add(fontMesh);

      const geometry = new THREE.CylinderGeometry(r, r, thick, 32);
      const material = new THREE.MeshBasicMaterial({ color: 0xcccccc });
      const cylinder = new THREE.Mesh(geometry, material);
      flGroup.add(cylinder);

      geometry.translate(x, y, z);

      const geometryC1 = new THREE.CircleGeometry(r, 32);
      geometryC1.rotateX(Math.PI / 2);
      geometryC1.translate(x, y + thick / 2, z);
      const edges = new THREE.EdgesGeometry(geometryC1);
      const lineMaterial = new THREE.LineBasicMaterial({
        color: "#000",
        linewidth: 1,
      });
      const cylinderLine = new THREE.LineSegments(edges, lineMaterial);
      flGroup.add(cylinderLine);

      const geometryC2 = new THREE.CircleGeometry(r, 32);
      geometryC2.rotateX(Math.PI / 2);
      geometryC2.translate(x, y - thick / 2 - 1, z);
      const edges2 = new THREE.EdgesGeometry(geometryC2);
      const lineMaterial2 = new THREE.LineBasicMaterial({
        color: "#000",
        linewidth: 1,
      });
      const cylinderLine2 = new THREE.LineSegments(edges2, lineMaterial2);
      flGroup.add(cylinderLine2);
      scene.add(flGroup);
      this.flGroup.push(flGroup);
      return flGroup;
    },
    DrawFLMP(x, y, z, xLength, yLength, zLength) {
      const geometry = new THREE.BoxBufferGeometry(xLength, yLength, zLength);
      geometry.translate(x, y, z);
      const edges = new THREE.EdgesGeometry(geometry);
      const lineMaterial = new THREE.LineBasicMaterial({
        color: "red",
        linewidth: 1,
      });
      const cubeLine = new THREE.LineSegments(edges, lineMaterial);
      scene.add(cubeLine);
    },
    DrawDB(x, y, z, xLength, yLength, zLength) {
      let fontGeo = new THREE.TextBufferGeometry("垫板", {
        size: 30, //字号大小，一般为大写字母的高度
        height: 1, //文字的厚度
        weight: "normal", //值为'normal'或'bold'，表示是否加粗
        font: this.font, //字体，默认是'helvetiker'，需对应引用的字体文件
        style: "normal", //值为'normal'或'italics'，表示是否斜体
        bevelThickness: 1, //倒角厚度
        bevelSize: 1, //倒角宽度
        curveSegments: 30, //弧线分段数，使得文字的曲线更加光滑
        bevelEnabled: true, //布尔值，是否使用倒角，意为在边缘处斜切
      });
      let fontMat = new THREE.MeshPhongMaterial({
        color: 0xcccccc,
        shininess: 60,
        specular: 0xcccccc,
      });
      let fontMesh = new THREE.Mesh(fontGeo, fontMat);
      fontGeo.rotateX(-Math.PI / 2);
      fontGeo.translate(x - 50, y + yLength / 2, z);

      scene.add(fontMesh);

      const geometry = new THREE.BoxBufferGeometry(xLength, yLength, zLength);
      geometry.translate(x, y, z);

      const material = new THREE.MeshBasicMaterial({ color: 0xcccccc });
      const cylinder = new THREE.Mesh(geometry, material);
      scene.add(cylinder);

      const edges = new THREE.EdgesGeometry(geometry);
      const lineMaterial = new THREE.LineBasicMaterial({
        color: "#000",
        linewidth: 1,
      });
      const cubeLine = new THREE.LineSegments(edges, lineMaterial);
      scene.add(cubeLine);
    },
    DrawDBMP(x, y, z, xLength, yLength, zLength) {
      const geometry = new THREE.BoxBufferGeometry(xLength, yLength, zLength);
      geometry.translate(x, y, z);
      const edges = new THREE.EdgesGeometry(geometry);
      const lineMaterial = new THREE.LineBasicMaterial({
        color: "red",
        linewidth: 1,
      });
      const cubeLine = new THREE.LineSegments(edges, lineMaterial);
      scene.add(cubeLine);
    },
    Merge() {
      if (this.flGroupCurrentHeight > 10) {
        this.flCount = self.setInterval(() => {
          this.TranslateFL(-10);
        }, 20);
      }
    },
    Separate() {
      if (this.flGroupCurrentHeight < 300) {
        this.flCount = self.setInterval(() => {
          this.TranslateFL(10);
        }, 20);
      }
    },
    TranslateFL(distance) {
      this.flGroup.forEach((item) => {
        item.translateY(distance);
      });
      this.flGroupCurrentHeight = this.flGroupCurrentHeight + distance;
      if (
        (this.flGroupCurrentHeight > 300) |
        (this.flGroupCurrentHeight < 10)
      ) {
        window.clearInterval(this.flCount);
      }
    },
    ChangeCamera() {
      if (this.cameratype == "p") {
        var width = window.innerWidth; //窗口宽度
        var height = window.innerHeight; //窗口高度
        var k = width / height; //窗口宽高比
        var s = 1500; //三维场景显示范围控制系数，系数越大，显示的范围越大
        //创建相机对象
        camera = new THREE.OrthographicCamera(-s * k, s * k, s, -s, 1, 10000);
        camera.position.set(0, 3000, 0); //设置相机位置
        camera.lookAt(0, 0, 0); //设置相机方向(指向的场景对象)
        //scene.add(camera);
        this.render();
        this.cameratype = "o";
      } else {
        camera = new THREE.PerspectiveCamera(
          60,
          window.innerWidth / window.innerHeight,
          1,
          10000
        );
        camera.position.set(0, 1500, 2500);
        controls = new OrbitControls(camera, renderer.domElement);
        this.render();
        this.cameratype = "p";
      }
    },
  },
};
</script>

<style>
td{
	    white-space: nowrap;
	}
</style>
